import lightModeLogo from 'assets/tradoLogo.svg';
import darkModeLogo from 'assets/tradoLogo.svg';
import minimalLogo from 'assets/minimal-logo.svg';

import footerLogo from 'assets/tradoLogo.svg';

declare global {
    interface Window {
        _env_: any;
    }
}

const env = {
    ...process.env,
    ...window._env_,
};

export const DEFAULT_PAGE_SIZE = 20;
export const headerHeight = 104;
export const scrolledHeaderHeight = 86;
export const headerTopHeight = 48;

export const VERSION = env.REACT_APP_VERSION;
export const ENVIRONMENT = env.APP_ENV;
export const API_URL = env.REACT_APP_API_URL;
export const GOOGLE_CLIENT_ID = env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const FB_APP_ID = env.REACT_APP_FB_ID;
export const imageTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif'];

export const mobileSizes = ['xs', 'sm'];

export { lightModeLogo, darkModeLogo, footerLogo, minimalLogo };

import { Box } from '@mui/material';
import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const RootComponent = styled(Box)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    '& .slider-dot': {
        cursor: 'pointer',
        height: '6px',
        borderRadius: 3,
        marginRight: theme.spacing(1),
        margiLeft: theme.spacing(1),
        transition: 'all .2s',
    },
}));

type SliderDotsProps = {
    numberOfDots: number;
    onClick: (index) => void;
    currentIndex: number;
};

export const SliderDots: React.FC<SliderDotsProps> = ({ numberOfDots, onClick, currentIndex }) => {
    return (
        <RootComponent>
            {Array(numberOfDots)
                .fill('')
                .map((_, index) => (
                    <Box
                        className="slider-dot"
                        sx={{
                            width: currentIndex === index ? '32px' : '24px',
                            backgroundColor: currentIndex === index ? 'rgba(59, 67, 242, 1)' : 'rgba(59, 67, 242, .15)',
                        }}
                        key={index}
                        onClick={() => onClick(index)}
                    ></Box>
                ))}
        </RootComponent>
    );
};

import {
    Box,
    Button,
    Dialog as MUIDialog,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const Dialog = styled(MUIDialog)(({ theme }: { theme: Theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(4, 6),
        minWidth: 512,
        maxWidth: 512,
        '& h2': {
            padding: 0,
        },
    },
    '& .header': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& p': {
            fontWeight: 600,
            fontSize: theme.spacing(3),
            color: '#475569',
            paddingTop: theme.spacing(10),
        },
    },
    '& .content': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: theme.spacing(3),
    },
    '& .description': {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: '14px',
        color: '#94A3B8',
    },
    '& .buttons': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '60px',
    },
    '& .button': {
        backgroundColor: '#1D4ED8',
        padding: theme.spacing(1.5, 4),
        fontWeight: 600,
    },
}));

type VerifyDialogProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: any;
    traderName?: string;
};

export const VerifyDialog: React.FC<VerifyDialogProps> = ({ open, onClose, onConfirm, traderName = '' }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
            <DialogTitle>
                <Box className="header">
                    <Typography>Verify your email</Typography>
                </Box>
            </DialogTitle>
            <DialogContent className="contentWrapper">
                <Box className="content">
                    <Typography className="description">
                        Hi{traderName !== '' ? ', ' + traderName : null}! Click on the button below to verify your email
                        and start trade!
                    </Typography>
                    <Box className="buttons">
                        <Button
                            onClick={() => {
                                onConfirm();
                                onClose();
                            }}
                            variant="contained"
                            className="button"
                            fullWidth
                        >
                            Verify
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

import { Box } from '@mui/material';
import { AuctionImage } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './styles.css';

type SliderProps = {
    auctionImages?: AuctionImage | any;
};

export const Slider: React.FC<SliderProps> = ({ auctionImages }) => {
    const [source, setSource] = useState(auctionImages[0]?.file?.url);

    useEffect(() => {
        setSource(auctionImages[0]?.file?.url);
    }, [auctionImages.length > 0]);

    return (
        <Box display="flex" alignItems="center" maxWidth="sm" gap="20px" flexDirection="column" justifyContent="center">
            <img style={{ height: 400, width: '100%', objectFit: 'cover' }} src={source} />
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                width="100%"
                height="20vh"
            >
                <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: '.review-swiper-button-next',
                        prevEl: '.review-swiper-button-prev',
                    }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    {auctionImages.map((auctionImage) => (
                        <SwiperSlide key={auctionImage.id}>
                            <img onClick={() => setSource(auctionImage.file.url)} src={auctionImage.file.url} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Box>
    );
};

import * as React from 'react';
import { styled, alpha } from '@mui/system';
// import SliderUnstyled from '@mui/base/SliderUnstyled';
import { SliderProps, SliderUnstyled } from '@mui/material';

const StyledSlider = styled(SliderUnstyled)(({ theme }) => ({
    color: theme.mode === 'dark' ? '#90caf9' : '#3B43F2',
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    touchAction: 'none',
    '-webkit-tap-highlight-color': 'transparent',

    '& .MuiSlider-rail': {
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '4px',
        borderRadius: '2px',
        backgroundColor: 'currentColor',
        opacity: '0.38',
    },

    '& .MuiSlider-track': {
        display: 'block',
        position: 'absolute',
        height: '4px',
        borderRadius: '2px',
        backgroundColor: 'currentColor',
    },

    '& .MuiSlider-thumb': {
        position: 'absolute',
        width: '14px',
        height: '14px',
        marginLeft: '-6px',
        marginTop: '-5px',
        boxSizing: ' border-box',
        borderRadius: '50%',
        outline: '0',
        border: '2px solid #fff',
        backgroundColor: 'currentColor',

        '&:hover ,&.Mui-focusVisible': {
            boxShadow: `0 0 0 0.25rem ${alpha(theme.mode === 'dark' ? '#90caf9' : '#1976d2', 0.15)}`,
        },

        '&.Mui-active': {
            boxShadow: `0 0 0 0.25rem ${alpha(theme.mode === 'dark' ? '#90caf9' : '#1976d2', 0.3)}`,
        },
    },
}));

export const UnStyledSlider: React.FC<SliderProps> = ({ value, min, max, step, onChange }) => {
    return <StyledSlider onChange={onChange} value={value} step={step} min={min} max={max} />;
};

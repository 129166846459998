import { DateTime } from 'luxon';
import { useState, useEffect } from 'react';

export const useCountdown = (
    date: string = new Date().toISOString(),
): { hours: number | string; minutes: number | string; seconds: number | string } => {
    const [hours, setHours] = useState<number | string>(0);
    const [minutes, setMinutes] = useState<number | string>(0);
    const [seconds, setSeconds] = useState<number | string>(0);

    const [timeLeft, setTimeLeft] = useState(
        Math.floor((DateTime.fromISO(date).minus({ hours: 4 }).toMillis() - DateTime.local().toMillis()) / 1000),
    );

    useEffect(() => {
        setHours(Math.floor(timeLeft / 3600) > 0 ? Math.floor(timeLeft / 3600) : '00');
        setMinutes(Math.floor(timeLeft % 3600) > 0 ? Math.floor((timeLeft % 3600) / 60) : '00');
        setSeconds(timeLeft % 60 > 0 ? timeLeft % 60 : '00');
    }, [timeLeft]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(
                Math.floor(
                    (DateTime.fromISO(date).minus({ hours: 4 }).toMillis() - DateTime.local().toMillis()) / 1000,
                ),
            );
        }, 1000);

        return () => clearInterval(intervalId);
    }, [date]);

    return { hours, minutes, seconds };
};
